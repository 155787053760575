function checkSuggestion(form){

  let requiredFields = form.find("input:required");
  return filledRequiredFields(requiredFields);
}

$(function(){

  let suggestForm = $("#suggestionForm");

  suggestForm.find("input[type='text']:required, textarea:required").on("blur", function(){
    let el = $(this);
    handleInput(el, minLength(el.val(), 2), errorMsgs.required);
  });

  suggestForm.on("submit", function(event) {

    if(checkSuggestion($(this))  == false){
      event.preventDefault();
      event.stopPropagation();
    }

  });

});
