function checkForgotForm(form){

  let email = form.find("input[type='email']");

  return validateEmail(email.val());

}

function checkResetForm(form){

  let pass = form.find("input[type='password']");

  return validatePass(pass.val());

}

$(function(){

  let forgotPassForm = $("#forgotPassForm");
  let resetPassForm = $("#resetPassForm");


  forgotPassForm.find("input[type='email']").on("blur", function(){
    let el = $(this);
    handleInput(el, validateEmail(el.val()), errorMsgs.email);
  });

  forgotPassForm.on("submit", function(event) {

    if(checkForgotForm($(this))  == false){
      event.preventDefault();
      event.stopPropagation();
    }

  });

  resetPassForm.find("input[type='password']").on("blur", function(){
    let el = $(this);
    handleInput(el, validatePass(el.val()), errorMsgs.password);
  });

  resetPassForm.on("submit", function(event) {

    event.preventDefault();
    event.stopPropagation();

    if(checkResetForm($(this))){


      let form = $(this);
      let btnF = form.find('input[name="resetpass"]');
      let url  = form.attr('action');
      let data = form.serialize();
      let display = form.find('.feedback');

      btnF.prop("disabled", true);

      $.ajax({

        type: 'POST',
        url: url,
        data: data,
        success: function(response){

          let json_response = JSON.parse(response);

          if(json_response.feedback){

            display.html(json_response.feedback);

          } else if(json_response.errors){

              renderErrorMsgsInForm(form, json_response.errors);
          }

        },
        error: function(error){

          display.html("Error:"+ error);
          btnF.prop("disabled", false);
        }

      });


    }

  });

});
