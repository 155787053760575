function toggleTab(el){
  let tab = $(el);
  let parent = tab.parent();
  let tabs = parent.parent();

  toggleActive(tab, tabs.find('.tab'));

  let tabIndex =  parent.index();
  goTab(tabIndex);

}


function goTab(tabIndex){

  let tabContent = $('.formContainer>div');
  toggleActive(tabContent.eq(tabIndex), tabContent);

}

function toggleActive(element, relatives){
  if(!element.hasClass('active')){
    relatives.filter( ".active" ).removeClass('active');
    element.addClass('active');
  }
}

function linkTo(url){
  window.location.href = url;
}

function removeQueryParms(){

  const uri = window.location.toString();

  if (uri.indexOf("?") > 0) {
    const clean_uri = uri.substring(0, uri.indexOf("?"));
    linkTo(clean_uri);
  }
}

function loadMore(event, total, step = 40) {
  const btn = $(event.target);
  const remaining = btn.closest('.loadContainer').find('.d-none');
  if(remaining.length > 0) {
    let startPos = remaining.eq(0).index();
    if(startPos <= total) {
      for(let i = 0; i< step; i++){
        if(startPos == total){
          btn.hide();
        }
        remaining.eq(i).removeClass('d-none');
        startPos++;
      }

    }else {
      btn.hide();
    }

  }else{
    btn.hide();
  }
}
