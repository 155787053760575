function validateForm(tag){

  let el = $(tag);
  let type = el.attr("type");
  let name = el.attr('name');
  let id = el.attr("id");
  let val = el.val();
  let form =  el.closest("#registerForm");

  if (type == "email") {
    handleInput(el, validateEmail(val), errorMsgs.email);
  } else if(type == "tel"){
    handleInput(el, validatePhone(val), errorMsgs.phone);
  } else if(type == "number"){
    handleInput(el, numericChar(val), errorMsgs.number);
  }else if(id === "registerPass"){
    handleInput(el, validatePass(val), errorMsgs.password);
  } else if(id === "registerPassConfirm") {
    let cond = validatePass(val) && val === $("#registerPass").val();
    handleInput(el, cond, errorMsgs.passNotMatch);
  }else if(el.hasClass('alphanum')) {
    handleInput(el, alphaNumChar(val), errorMsgs.required);
  }else if(name = 'businessLoc'){
    handleInput(el, minLength(val,1), errorMsgs.required);
  } else if(el.prop("required")){
    if(type == "text"){
      handleInput(el, minLength(val, 2), errorMsgs.required);
    }else if(el.prop("tagName") == "SELECT"){
      handleInput(el, minLength(val, 1), errorMsgs.required);
    }
  }

}

function getUserAvailability(email){

  let url = $('#loginForm').attr("action");
  return $.ajax({
    type: 'GET',
    dataType: 'json',
    url: `${url}/.json`,
    data: {'email': email},
  });
}

function checkRegisterFields(){

  let registerForm = $("#registerForm");
  let passFieldVal = registerForm.find("#registerPass").val();
  let passConfVal = registerForm.find("#registerPassConfirm").val();
  let emailFieldVal = registerForm.find("#emailAddress").val();
  let email = validateEmail(emailFieldVal);
  let pass = validatePass(passFieldVal);
  let passConfirm = validatePass(passConfVal) && passConfVal === passFieldVal;

  return email && pass && passConfirm;
}

function handleRegister(btn){

  let registerForm = $("#registerForm");
  let emailFieldVal = registerForm.find("#emailAddress").val();
  let display = registerForm.find('.email-feedback');
  let passwordFields = registerForm.find("input[type='password']");

  getUserAvailability(emailFieldVal).done(function(userAvail){
    if(!userAvail){

      display.siblings('input').addClass('is-invalid');
      display.html('email already in use');
      passwordFields.val('');

    } else {
      display.siblings('input').removeClass('is-invalid');
      display.html('');
      goTab(2);

    }
    btn.prop("disabled", false);
  })
    .fail(function(error){
      console.log(`error: ${error}`);
      btn.prop("disabled", false);
    });
}

function goNext(e){

  e.preventDefault();
  let btn = $(e.target);
  if(checkRegisterFields()){
    btn.prop("disabled", true);
    handleRegister(btn);
  }

}

$(function(){

  let select = $('#businessType');
  let sales = false;

  select.on('change', function(){
    const externalContent =  $('.external-user');
    if($(this).val() == "Monin Sales"){
      externalContent.addClass('d-none');
      sales = true;
    }else{
      sales = false;
      if(externalContent.hasClass('d-none')){
        externalContent.removeClass('d-none');
      }
    }
  });

  let registerForm = $("#registerForm");
  let submitBtn = $('.business-section').find("input[type='submit']");
  registerForm.on("submit", function(event) {
    event.preventDefault();
    const form = $(this);
    let submitBtn = form.find('input[type="submit"]');
    let display = $('.business-section .text-danger');
    let url = form.attr("action");
    let data = form.serialize();

    if(checkRegisterFields() && checkBusiness()){
      $.ajax({
        type: 'POST',
        dataType: 'json',
        url: `${url}/.json`,
        data: data,

        success: function(response){
          if(response.success == true) {
            $('.thanks-sections').removeClass('d-none');
            $('.unregistered').addClass('d-none');
          }else if(response.error){
            display.html(`Issue with your registration: ${response.error}`);
          }
          submitBtn.prop("disabled", false);
        },
        error: function(xhr){
          display.html(xhr.responseText);
          submitBtn.prop("disabled", false);
        }
      });
    }
  });

  function checkBusiness(){

    let businessSec = $(".business-section");
    let requiredFields = businessSec.find("input:required[type='text']");
    let validPhone = validatePhone(businessSec.find("input:required[type='tel']").val());
    let validNumber = numericChar(businessSec.find("input:required[type='number']").val());

    if(sales){
      validPhone = true;
      validNumber = true
      let externalFields = businessSec.find('.external-user').find("input:required[type='text']");
      requiredFields = requiredFields.not(externalFields);
    }

    return businessSec.find("#businessType").val() != "" && filledRequiredFields(requiredFields) && validPhone && validNumber;

  }

});
