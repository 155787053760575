let isLoadingModal = false;

function ajaxModal(url, doc){

  let $wrapper = $('#modalWrapper');

  if($wrapper.is(":empty") && !isLoadingModal){

    isLoadingModal = true;

    $.get(`${url}.json`, {doc: doc}, function(response){

      isLoadingModal = false;

      if(response.html){

        $wrapper.html(response.html);
        $('.modal', $wrapper).modal();
      }
    });

  }
}

$('body').on('hidden.bs.modal', '#categoryModal', function(){
  $('#modalWrapper').empty();
});

$(function(){

  let fileContainer = $('.filesContainer');
  const categoryItems = fileContainer.find('.itemContainer');
  let hiddenElements = null;

  function download(event){
    event.preventDefault();
    event.stopPropagation();

    let form = $(event.target);

    if(atLeastOneCheckbox(form)){

      let filesIndexes = [];
      let values = [];

      form.find('input[name="files[]"]:checked').each(function() {
        filesIndexes.push($(this).closest('.singleFile').index());
        values.push({name: this.name, value: $(this).val()});
      });

      form.find('input[name="pageuri[]"]').each(function(index){
        if(filesIndexes.indexOf(index) != -1){
          values.push({name: this.name, value: $(this).val()});
        }
      });

      let downloadURI = decodeURIComponent($.param(values));
      const downloadedURL= `${siteUrl}/downloadImages/${downloadURI}`;
      window.location = downloadedURL;
    }
  }

  $('.InstaDownload').on('click', function(event){
      event.preventDefault();
      const fileBoxElement = $(this).closest('.fileContainer');
      let values = {};
      fileBoxElement.find('input').each(function() {
        values[this.name] = $(this).val();
      });
      let downloadURI = decodeURIComponent($.param(values));
      const downloadedURL= `${siteUrl}/downloadImages/${downloadURI}`;
      window.location = downloadedURL;
  });

  $('#modalWrapper').on("submit", ".downloadForm", download);
  $('.downloadSingleForm').on("submit",  download);
  $('.dropdown-menu .dropdown-item').click(function(){
    const text = $(this).text();
    const btnDistplay =  $(this).closest('.dropdown').find('.btn');
    btnDistplay.html(text);

    const category = $(this).data('value');

    if(hiddenElements){
      hiddenElements.show();
    }

    if(category !=="all"){

      const filteredItems = categoryItems.filter(`[data-page="${category}"]`);
      hiddenElements = categoryItems.not(filteredItems);
      hiddenElements.hide();
    }

  });

});
