function validateLogin(tag) {

  let el = $(tag);
  let type = el.attr("type");
  let val = el.val();

  if (type == "email") {
    handleInput(el, validateEmail(val), errorMsgs.email);
  }else if(el.prop("required")){
    handleInput(el, minLength(val, 2), errorMsgs.required);
  }
}

$(function(){

  let loginForm = $("#loginForm");

  loginForm.on("submit", function(event) {

    event.preventDefault();
    event.stopPropagation();

    let form = $(this);
    let url = form.attr("action");
    let data = form.serialize();
    let display = form.find('.text-danger');
    let submitBtn = form.find('input[type="submit"]')

    submitBtn.prop("disabled", true);

    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: `${url}/.json`,
      data: data,

      success: function(response){
        if(response.redirect == true) {
          location.reload();
        }else if(response.error){
          display.html(response.error);
        }
        submitBtn.prop("disabled", false);
      },
      error: function(xhr){
        display.html(xhr.responseText);
        submitBtn.prop("disabled", false);
      }
    });

  });

});
