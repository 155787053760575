const errorMsgs = {
  "email": "Please enter a valid email",
  "password": "Your password should contain more than 4 characters",
  "passNotMatch": "Password doesn't match",
  "phone": "Please enter a valid phone number",
  "number" : "Please enter a numeric value",
  "required": "This field is required please enter a value"
}

function filledRequiredFields(requiredInputs){

  let requiredPass = true;

  requiredInputs.each(function(){
    if(!minLength($(this).val(),2)){
      requiredPass = false;
    }
  });

  return requiredPass;

}

function handleInput(el, cond, errorMsg){
  if (cond) {
    el.removeClass("is-invalid");
  } else {
    el.addClass("is-invalid");
    el.siblings(".invalid-feedback").html(errorMsg);
  }
}

function validateEmail (email) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validatePhone (phone) {
  let reg = /^[0-9]{6}(-){0,1}[0-9]{4}$/ // validate 123456-7891 or 1234567891
  return reg.test(phone);
}

function validatePass(pass){ // by now it validates password contains more than 4 charact
  return minLength(pass, 4);
}

function sameText(first, second){
  return first === second;
}

function minLength(val, minChar){
  return val.length >= minChar;
}

function alphaNumChar(val){
  let alphanumeric = /^[a-zA-Z0-9\s]+$/;
  return alphanumeric.test(val);
}

function numericChar(val){
  let numeric = /^[0-9\s]+$/;
  return numeric.test(val);
}

function atLeastOneCheckbox(form){

  let checkboxes = form.find("input[type='checkbox']");
  if(checkboxes <= 0) return false;

  let pass = false;

  checkboxes.each(function(i,e){
    if($(e).is(":checked")){
      pass = true;
      return false;
    }
  });

  return pass;

}

function renderErrorMsgsInForm(form, errors){

  for(var name in errors){

    let input = form.find('input[name='+name+']');
    input.addClass('is-invalid');
    input.sibling('.invalid-feedback').html(errors[name]);
  }

}
